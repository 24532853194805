import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
// import moment from 'moment';
import BasicModal from "../Common/BasicModel"
import AudioPlayer from "./AudioPlayer"

const Playback = ({ callRecList }) => {
    const [currentRec, setCurrentRec] = useState({})
    const [currentCLTranscript, setCurrentCLTranscript] = useState([])
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [hmmNumber, setHmmNumber] = useState(0)
    const [confirmedContact, setConfirmedContact] = useState(false)
    const [callLength, setCallLength] = useState(0)
    const [callerTalkTime, setCallerTalkTime] = useState(0)
    const [followedScript, setFollowedScript] = useState(0)
    const [analysisScore, setAnalysisScore] = useState(0)

    const callTypeTitle = {
        NoAns: 'No Answer',
        FirstAppt: 'Booked',
        HangUp: 'Hung Up',
        EmailSent: 'Email',
        Callback: 'Callback',
        NotInterested: 'Not Interested',
        DoNotCall: 'Do Not Call',
    };

    // Get the current time in the local time zone
    const localTime = moment();
    // Get the current time in the America/Chicago time zone
    const chicagoTime = moment.tz("America/Chicago");
    // Calculate the difference in hours
    const timeDiff = localTime.utcOffset() - chicagoTime.utcOffset();

    const getLocalTimeStampForRecording = (timestamp, format) => {
        const centralTime = moment.utc(timestamp) // Because SQL server is at CDT/CST
        const localUserTime = centralTime.clone().add(timeDiff, 'minutes')

        return localUserTime.format(format)
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    const initStoplightValues = () => {
        setFollowedScript(0)
        setCallerTalkTime(0)
        setHmmNumber(0)
        setConfirmedContact(0)
        setAnalysisScore(0)
    }

    function checkForConfirmationRequest(callerTranscripts) {
        const wholeText = callerTranscripts.map(item => item.text).join(' ');
        // Regular expression to find phrases asking to confirm phone or email
        const emailPattern1 = /Can you confirm your email (address)?/i;
        const emailPattern2 = /I have your email as/i;
        const emailPattern3 = /can I confirm that your email/i;
        const phonePattern1 = /Can you confirm your phone (number)?/i;
        const phonePattern2 = /What is the (best)? phone (number)? to reach you/i;

        // Check if the text contains requests to confirm phone number or email address
        const asksForPhoneConfirmation = phonePattern1.test(wholeText) || phonePattern2.test(wholeText);
        const asksForEmailConfirmation = emailPattern1.test(wholeText) || emailPattern2.test(wholeText) || emailPattern3.test(wholeText);

        // Create a result object to report findings
        return (asksForPhoneConfirmation || asksForEmailConfirmation);
    }

    function countFillerWords(callerTranscripts) {
        // concat text field of all transcripts
        const wholeText = callerTranscripts.map(item => item.text).join(' ');
        const cleanedText = wholeText.replace(/[^\w\s]/g, '').toLowerCase();
        const fillerWords = ['hmm', 'umm', 'uh', 'ah', 'um', 'uhm', 'mhm'];
        // Create a regular expression from the filler words array
        const pattern = new RegExp(`\\b(${fillerWords.join('|')})\\b`, 'gi');
        // Find all matches in the text
        const matches = wholeText.match(pattern);
        // Count the occurrences
        const count = matches ? matches.length : 0;
        
        return count;
    }

    function calcFollowedScript(transcriptCL) {
        const calllerTranscripts = transcriptCL.filter(obj => obj.channel === '1').map(item => item.text).join(' ');
        const wholeTranscripts = transcriptCL.map(item => item.text).join(' ');
        // Remove punctuation and convert to lowercase for both the opening line and the entire text
        const callerText = calllerTranscripts.replace(/[^\w\s]/g, '').toLowerCase();
        const wholeText = wholeTranscripts.replace(/[^\w\s]/g, '').toLowerCase();

        // Split into words
        const callerWords = callerText.split(/\s+/).filter(Boolean);
        const allWords = wholeText.split(/\s+/).filter(Boolean);
        // Calculate total word counts
        const totalWordsInCaller = callerWords.length;
        const totalWordsInText = allWords.length;

        // Calculate the percentage of the total word count that comes from caller
        const percentage = (totalWordsInCaller / totalWordsInText * 100).toFixed(0);

        return percentage;
    }

    function calculateCallerTalkTime(transcriptCL) {
        const callerTimeArr = transcriptCL.filter(obj => obj.channel === '1').map(item => (item.end - item.start));
        const wholeTimeArr = transcriptCL.map(item => (item.end - item.start));
        const callerTime = callerTimeArr.reduce((acc, curr) => acc + curr, 0);
        const wholeTime = wholeTimeArr.reduce((acc, curr) => acc + curr, 0);

        // Calculate the percentage of the total word count that comes from caller
        const percentage = (callerTime / wholeTime * 100).toFixed(0);

        return percentage;
    }

    useEffect(() => {
        // Spotlight analysis
        if (currentRec.CLTranscript) {
            const transcriptCL = JSON.parse(currentRec.CLTranscript)
            const calllerTranscript = transcriptCL.filter(obj => obj.channel === '1');
            if (calllerTranscript.length) {
                const followedScriptPercent = calcFollowedScript(transcriptCL)
                const callerTimePercent = calculateCallerTalkTime(transcriptCL)
                const hmmCount = countFillerWords(calllerTranscript)
                const contactConfirmStatus = checkForConfirmationRequest(calllerTranscript)
                setCallerTalkTime(callerTimePercent)
                setFollowedScript(followedScriptPercent)
                setHmmNumber(hmmCount)
                setConfirmedContact(contactConfirmStatus)
                const followedScriptScore = Math.max(0, 45 - 0.5 * (100 - followedScriptPercent))
                var callerTimeScore = 30
                if (callerTimePercent > 60) {
                    callerTimeScore -= 0.5 * (callerTimePercent - 60)
                } else if (callerTimePercent < 40) {
                    callerTimeScore -= 0.5 * (40 - callerTimePercent)
                }
                const callLengthScore = Math.max(0, 20 - 5 * (300 - callLength) / 60)
                const confirmScore = 5
                const totalScore = followedScriptScore + callerTimeScore + callLengthScore + confirmScore - hmmNumber
                setAnalysisScore(totalScore)
            } else {
                initStoplightValues()
            }
        } else {
            initStoplightValues()
        }

        const audio = new Audio(currentRec.RecordingURL);
        const handleMetadata = () => {
            setCallLength(audio.duration);
        };
        audio.addEventListener('loadedmetadata', handleMetadata);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            audio.removeEventListener('loadedmetadata', handleMetadata);
        };
    }, [currentRec, callLength]);

    return (
        <div className='overflow-auto max-w-full'>
            <div className="rounded-xl round gap-3 items-center max-h-[380px] h-max-h-[480px] overflow-auto playback-scrollbar pr-2">
                {callRecList.length > 0 && (
                    callRecList.map((item, index) => (
                        <div key={index} className='flex min-w-min lg:text-xs 2xl:text-base gap-3 rounded-full bg-secondary/10 lg:p-1 2xl:p-2 mt-3' style={{ color: '#0F0F74' }}>
                            <div className='min-w-32 flex-1 flex items-center text-center justify-center font-semibold'>
                                {item.ContactName}
                            </div>
                            <div className='min-w-28 flex-1 flex-col'>
                                <div className='flex items-center'>
                                    <span class="inline-block lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"></path>
                                        </svg>
                                    </span>
                                    <span class="ml-2 font-semibold whitespace-nowrap">{getLocalTimeStampForRecording(item.TimeStamp, 'MM-DD-YYYY')}</span>
                                </div>
                                <div className='flex items-center'>
                                    <span class="inline-block lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </span>
                                    <span class="ml-2 font-semibold whitespace-nowrap">{getLocalTimeStampForRecording(item.TimeStamp, 'hh:mm:ss A')}</span>
                                </div>
                            </div>
                            <div className='min-w-28 flex items-center text-center justify-center font-semibold'>{callTypeTitle[item.CallType]}</div>
                            <div className='min-w-28 flex items-center gap-3'>
                                <span class={`cursor-pointer inline-block lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 flex items-center justify-center`}
                                    onClick={() => { handleOpen(); setCurrentRec(item); }}
                                    data-hs-overlay="#recording-playback-modal">
                                    <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 13.1034C7.53401 13.1024 8.52537 12.6879 9.25653 11.9509C9.98769 11.2139 10.3989 10.2147 10.4 9.17241V3.93103C10.4 2.88846 9.98911 1.88859 9.25772 1.15137C8.52632 0.414161 7.53434 0 6.5 0C5.46566 0 4.47368 0.414161 3.74228 1.15137C3.01089 1.88859 2.6 2.88846 2.6 3.93103V9.17241C2.60108 10.2147 3.01231 11.2139 3.74347 11.9509C4.47463 12.6879 5.46599 13.1024 6.5 13.1034ZM3.9 3.93103C3.9 3.23598 4.17393 2.5694 4.66152 2.07793C5.14912 1.58645 5.81044 1.31034 6.5 1.31034C7.18956 1.31034 7.85088 1.58645 8.33848 2.07793C8.82607 2.5694 9.1 3.23598 9.1 3.93103V9.17241C9.1 9.86746 8.82607 10.534 8.33848 11.0255C7.85088 11.517 7.18956 11.7931 6.5 11.7931C5.81044 11.7931 5.14912 11.517 4.66152 11.0255C4.17393 10.534 3.9 9.86746 3.9 9.17241V3.93103ZM7.15 15.6914V18.3448C7.15 18.5186 7.08152 18.6852 6.95962 18.8081C6.83772 18.931 6.67239 19 6.5 19C6.32761 19 6.16228 18.931 6.04038 18.8081C5.91848 18.6852 5.85 18.5186 5.85 18.3448V15.6914C4.24746 15.527 2.76231 14.7695 1.68175 13.5654C0.601186 12.3613 0.00197934 10.796 0 9.17241C0 8.99865 0.0684821 8.83201 0.190381 8.70914C0.312279 8.58627 0.477609 8.51724 0.65 8.51724C0.822391 8.51724 0.987721 8.58627 1.10962 8.70914C1.23152 8.83201 1.3 8.99865 1.3 9.17241C1.3 10.5625 1.84786 11.8957 2.82304 12.8786C3.79823 13.8616 5.12087 14.4138 6.5 14.4138C7.87913 14.4138 9.20177 13.8616 10.177 12.8786C11.1521 11.8957 11.7 10.5625 11.7 9.17241C11.7 8.99865 11.7685 8.83201 11.8904 8.70914C12.0123 8.58627 12.1776 8.51724 12.35 8.51724C12.5224 8.51724 12.6877 8.58627 12.8096 8.70914C12.9315 8.83201 13 8.99865 13 9.17241C12.998 10.796 12.3988 12.3613 11.3183 13.5654C10.2377 14.7695 8.75254 15.527 7.15 15.6914Z" fill="#0F0F74" />
                                    </svg>
                                </span>
                                <span class="cursor-pointer inline-block lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 flex items-center justify-center"
                                    onClick={() => {
                                        handleOpen1();
                                        setCurrentRec(item);
                                        if(item && item.CLTranscript){
                                            setCurrentCLTranscript(JSON.parse(item.CLTranscript));
                                        }
                                        }}>
                                    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.8006 5.32913L10.0278 0.21375C9.96447 0.14591 9.88926 0.0921149 9.80649 0.055439C9.72372 0.0187631 9.63502 -7.52589e-05 9.54545 2.25953e-07H1.36364C1.00198 2.25953e-07 0.655131 0.153983 0.3994 0.428075C0.143668 0.702166 0 1.07391 0 1.46154V17.5385C0 17.9261 0.143668 18.2978 0.3994 18.5719C0.655131 18.846 1.00198 19 1.36364 19H13.6364C13.998 19 14.3449 18.846 14.6006 18.5719C14.8563 18.2978 15 17.9261 15 17.5385V5.84615C15.0001 5.75016 14.9825 5.65509 14.9483 5.56638C14.9141 5.47767 14.8639 5.39705 14.8006 5.32913ZM10.2273 2.49466L12.6724 5.11539H10.2273V2.49466ZM13.6364 17.5385H1.36364V1.46154H8.86364V5.84615C8.86364 6.03997 8.93547 6.22584 9.06334 6.36289C9.1912 6.49993 9.36462 6.57692 9.54545 6.57692H13.6364V17.5385ZM10.9091 10.2308C10.9091 10.4246 10.8373 10.6105 10.7094 10.7475C10.5815 10.8845 10.4081 10.9615 10.2273 10.9615H4.77273C4.5919 10.9615 4.41847 10.8845 4.29061 10.7475C4.16274 10.6105 4.09091 10.4246 4.09091 10.2308C4.09091 10.037 4.16274 9.85108 4.29061 9.71404C4.41847 9.57699 4.5919 9.5 4.77273 9.5H10.2273C10.4081 9.5 10.5815 9.57699 10.7094 9.71404C10.8373 9.85108 10.9091 10.037 10.9091 10.2308ZM10.9091 13.1538C10.9091 13.3477 10.8373 13.5335 10.7094 13.6706C10.5815 13.8076 10.4081 13.8846 10.2273 13.8846H4.77273C4.5919 13.8846 4.41847 13.8076 4.29061 13.6706C4.16274 13.5335 4.09091 13.3477 4.09091 13.1538C4.09091 12.96 4.16274 12.7742 4.29061 12.6371C4.41847 12.5001 4.5919 12.4231 4.77273 12.4231H10.2273C10.4081 12.4231 10.5815 12.5001 10.7094 12.6371C10.8373 12.7742 10.9091 12.96 10.9091 13.1538Z" fill="#0F0F74" />
                                    </svg>
                                </span>
                                <div class="relative flex flex-col items-center group">
                                    <span class="cursor-pointer inline-block lg:w-5 lg:h-5 2xl:w-6 2xl:h-6 flex items-center justify-center" onClick={() => { setCurrentRec(item); handleOpen2() }}>
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_432_384)">
                                                <path d="M17.25 10.9615H15.75V5.11538H17.25C17.6625 5.11538 18 4.79385 18 4.38462C18 3.97538 17.6625 3.65385 17.25 3.65385H15.75V1.46154C15.75 0.657692 15.0825 0 14.25 0H3.75C2.925 0 2.25 0.657692 2.25 1.46154V3.65385H0.75C0.3375 3.65385 0 3.98269 0 4.38462C0 4.78654 0.3375 5.11538 0.75 5.11538H2.25V10.9615H0.75C0.3375 10.9615 0 11.2904 0 11.6923C0 12.0942 0.3375 12.4231 0.75 12.4231H2.25V17.5385C2.25 18.3496 2.925 19 3.75 19H14.25C15.0825 19 15.75 18.3496 15.75 17.5385V12.4231H17.25C17.6625 12.4231 18 12.1015 18 11.6923C18 11.2831 17.6625 10.9615 17.25 10.9615ZM14.25 17.5385H3.75V1.46154H14.25V17.5385Z" fill="#0F0F74" />
                                                <path d="M8.99953 7.20508C7.70203 7.20508 6.64453 8.23546 6.64453 9.49969C6.64453 10.7639 7.69453 11.7943 8.99953 11.7943C10.3045 11.7943 11.3545 10.7712 11.3545 9.49969C11.3545 8.22816 10.3045 7.20508 8.99953 7.20508ZM8.99953 10.647C8.35453 10.647 7.82203 10.1355 7.82203 9.49969C7.82203 8.86392 8.35453 8.35239 8.99953 8.35239C9.64453 8.35239 10.177 8.87123 10.177 9.49969C10.177 10.1282 9.65203 10.647 8.99953 10.647Z" fill="#0F0F74" />
                                                <path d="M8.99953 12.3569C7.70203 12.3569 6.64453 13.3873 6.64453 14.6515C6.64453 15.9158 7.69453 16.9462 8.99953 16.9462C10.3045 16.9462 11.3545 15.9231 11.3545 14.6515C11.3545 13.38 10.3045 12.3569 8.99953 12.3569ZM8.99953 15.7989C8.35453 15.7989 7.82203 15.2873 7.82203 14.6515C7.82203 14.0158 8.35453 13.5042 8.99953 13.5042C9.64453 13.5042 10.177 14.0231 10.177 14.6515C10.177 15.28 9.65203 15.7989 8.99953 15.7989Z" fill="#0F0F74" />
                                                <path d="M8.99953 2.05322C7.70203 2.05322 6.64453 3.08361 6.64453 4.34784C6.64453 5.61207 7.69453 6.64245 8.99953 6.64245C10.3045 6.64245 11.3545 5.61938 11.3545 4.34784C11.3545 3.0763 10.3045 2.05322 8.99953 2.05322ZM8.99953 5.49515C8.35453 5.49515 7.82203 4.98361 7.82203 4.34784C7.82203 3.71207 8.35453 3.20053 8.99953 3.20053C9.64453 3.20053 10.177 3.71938 10.177 4.34784C10.177 4.9763 9.65203 5.49515 8.99953 5.49515Z" fill="#0F0F74" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_432_384">
                                                    <rect width="18" height="19" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    {/* <div className="absolute -bottom-0 right-0 h-9 w-64 flex-col items-center hidden mb-6 group-hover:flex" style={{ zIndex: 99 }}>
                                        <span className="relative z-10 p-2 text-base bg-accent-1 rounded-lg">
                                            Stoplight Analysis coming soon!
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )
                    ))}
            </div>
            <BasicModal open={open} handleClose={handleClose} handleOpen={handleOpen}>
                <div
                    id="recording-playback-modal"
                    className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
                >
                    <div
                        className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl p-5">
                        <div className="border-2 border-blue-900 border-solid p-4 pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm text-blue-900 font-semibold">
                            <div className="flex w-full justify-between">
                                <div className='flex'>
                                    <span class="inline-flex justify-start items-center border-transparent">
                                        <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"></path>
                                        </svg>
                                    </span>
                                    <span className='p-2'>{currentRec.ContactName}</span>
                                    <span className='p-2'>{getLocalTimeStampForRecording(currentRec.TimeStamp, 'MM-DD-YYYY hh:mm:ss A')}</span>
                                </div>
                                <button onClick={handleClose} className='inline-flex justify-end items-center border-transparent'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </button>
                            </div>
                            <AudioPlayer src={currentRec.RecordingURL} />
                        </div>
                    </div>
                </div>
            </BasicModal>
            <BasicModal open={open1} handleClose={handleClose1} handleOpen={handleOpen1}>
                <div
                    id="transcript-modal"
                    className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
                >
                    <div
                        className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl p-5">
                        <div className="border-2 border-blue-900 border-solid p-4 pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm text-blue-900 font-semibold">
                            <div className="flex w-full justify-between">
                                <div className='flex'>
                                    <span class="inline-flex justify-start items-center border-transparent">
                                        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.8006 5.32913L10.0278 0.21375C9.96447 0.14591 9.88926 0.0921149 9.80649 0.055439C9.72372 0.0187631 9.63502 -7.52589e-05 9.54545 2.25953e-07H1.36364C1.00198 2.25953e-07 0.655131 0.153983 0.3994 0.428075C0.143668 0.702166 0 1.07391 0 1.46154V17.5385C0 17.9261 0.143668 18.2978 0.3994 18.5719C0.655131 18.846 1.00198 19 1.36364 19H13.6364C13.998 19 14.3449 18.846 14.6006 18.5719C14.8563 18.2978 15 17.9261 15 17.5385V5.84615C15.0001 5.75016 14.9825 5.65509 14.9483 5.56638C14.9141 5.47767 14.8639 5.39705 14.8006 5.32913ZM10.2273 2.49466L12.6724 5.11539H10.2273V2.49466ZM13.6364 17.5385H1.36364V1.46154H8.86364V5.84615C8.86364 6.03997 8.93547 6.22584 9.06334 6.36289C9.1912 6.49993 9.36462 6.57692 9.54545 6.57692H13.6364V17.5385ZM10.9091 10.2308C10.9091 10.4246 10.8373 10.6105 10.7094 10.7475C10.5815 10.8845 10.4081 10.9615 10.2273 10.9615H4.77273C4.5919 10.9615 4.41847 10.8845 4.29061 10.7475C4.16274 10.6105 4.09091 10.4246 4.09091 10.2308C4.09091 10.037 4.16274 9.85108 4.29061 9.71404C4.41847 9.57699 4.5919 9.5 4.77273 9.5H10.2273C10.4081 9.5 10.5815 9.57699 10.7094 9.71404C10.8373 9.85108 10.9091 10.037 10.9091 10.2308ZM10.9091 13.1538C10.9091 13.3477 10.8373 13.5335 10.7094 13.6706C10.5815 13.8076 10.4081 13.8846 10.2273 13.8846H4.77273C4.5919 13.8846 4.41847 13.8076 4.29061 13.6706C4.16274 13.5335 4.09091 13.3477 4.09091 13.1538C4.09091 12.96 4.16274 12.7742 4.29061 12.6371C4.41847 12.5001 4.5919 12.4231 4.77273 12.4231H10.2273C10.4081 12.4231 10.5815 12.5001 10.7094 12.6371C10.8373 12.7742 10.9091 12.96 10.9091 13.1538Z" fill="#0F0F74" />
                                        </svg>
                                    </span>
                                    <span className='p-2 inline-flex justify-start items-center border-transparent'>{currentRec.ContactName}</span>
                                    <span className='p-2 inline-flex justify-start items-center border-transparent'>{getLocalTimeStampForRecording(currentRec.TimeStamp, 'MM-DD-YYYY')}</span>
                                </div>
                                <button onClick={handleClose1} className='inline-flex justify-end items-center border-transparent' data-hs-overlay="#transcript-modal">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </button>
                            </div>
                            <div className='p-5 bg-clip-text text-gray overflow-y-auto max-h-[340px] [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2'>
                                { currentCLTranscript && currentCLTranscript.length > 0 && currentCLTranscript.map((transcript, index) => (
                                    <p key={index} className={`${transcript.channel === '1' ? 'text-blue-900' : 'text-gray-500'} mb-2`}>
                                        {transcript.channel === '1' ? 'Caller: ' + transcript.text : 'Prospect: ' + transcript.text}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </BasicModal>
            <BasicModal open={open2} handleClose={handleClose2} handleOpen={handleOpen2}>
                <div
                    id="spotlight-modal"
                    className="hs-overlay pointer-events-none z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none absolute left-[35%] top-[30%] -translate-x-[50%]"
                >
                    <div
                        className="m-3 mt-0 transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl p-5">
                        <div className="border-2 border-blue-900 border-solid p-4 pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm text-blue-900 font-semibold">
                            <div className="flex w-full justify-between">
                                <div className='flex'>
                                    <span class="inline-flex justify-start items-center border-transparent">
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_432_384)">
                                                <path d="M17.25 10.9615H15.75V5.11538H17.25C17.6625 5.11538 18 4.79385 18 4.38462C18 3.97538 17.6625 3.65385 17.25 3.65385H15.75V1.46154C15.75 0.657692 15.0825 0 14.25 0H3.75C2.925 0 2.25 0.657692 2.25 1.46154V3.65385H0.75C0.3375 3.65385 0 3.98269 0 4.38462C0 4.78654 0.3375 5.11538 0.75 5.11538H2.25V10.9615H0.75C0.3375 10.9615 0 11.2904 0 11.6923C0 12.0942 0.3375 12.4231 0.75 12.4231H2.25V17.5385C2.25 18.3496 2.925 19 3.75 19H14.25C15.0825 19 15.75 18.3496 15.75 17.5385V12.4231H17.25C17.6625 12.4231 18 12.1015 18 11.6923C18 11.2831 17.6625 10.9615 17.25 10.9615ZM14.25 17.5385H3.75V1.46154H14.25V17.5385Z" fill="#0F0F74" />
                                                <path d="M8.99953 7.20508C7.70203 7.20508 6.64453 8.23546 6.64453 9.49969C6.64453 10.7639 7.69453 11.7943 8.99953 11.7943C10.3045 11.7943 11.3545 10.7712 11.3545 9.49969C11.3545 8.22816 10.3045 7.20508 8.99953 7.20508ZM8.99953 10.647C8.35453 10.647 7.82203 10.1355 7.82203 9.49969C7.82203 8.86392 8.35453 8.35239 8.99953 8.35239C9.64453 8.35239 10.177 8.87123 10.177 9.49969C10.177 10.1282 9.65203 10.647 8.99953 10.647Z" fill="#0F0F74" />
                                                <path d="M8.99953 12.3569C7.70203 12.3569 6.64453 13.3873 6.64453 14.6515C6.64453 15.9158 7.69453 16.9462 8.99953 16.9462C10.3045 16.9462 11.3545 15.9231 11.3545 14.6515C11.3545 13.38 10.3045 12.3569 8.99953 12.3569ZM8.99953 15.7989C8.35453 15.7989 7.82203 15.2873 7.82203 14.6515C7.82203 14.0158 8.35453 13.5042 8.99953 13.5042C9.64453 13.5042 10.177 14.0231 10.177 14.6515C10.177 15.28 9.65203 15.7989 8.99953 15.7989Z" fill="#0F0F74" />
                                                <path d="M8.99953 2.05322C7.70203 2.05322 6.64453 3.08361 6.64453 4.34784C6.64453 5.61207 7.69453 6.64245 8.99953 6.64245C10.3045 6.64245 11.3545 5.61938 11.3545 4.34784C11.3545 3.0763 10.3045 2.05322 8.99953 2.05322ZM8.99953 5.49515C8.35453 5.49515 7.82203 4.98361 7.82203 4.34784C7.82203 3.71207 8.35453 3.20053 8.99953 3.20053C9.64453 3.20053 10.177 3.71938 10.177 4.34784C10.177 4.9763 9.65203 5.49515 8.99953 5.49515Z" fill="#0F0F74" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_432_384">
                                                    <rect width="18" height="19" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span className='p-2 inline-flex font-bold justify-start items-center border-transparent'>Stoplight Analysis</span>
                                </div>
                                <button onClick={handleClose2} className='inline-flex justify-end items-center border-transparent' data-hs-overlay="#spotlight-modal">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </button>
                            </div>
                            <h6 className='flex justify-between text-primary text-center font-semibold px-3 py-3 mb-3'>
                                <span>{currentRec.ContactName}, {currentRec.CompanyName}</span>
                                <span>{getLocalTimeStampForRecording(currentRec.TimeStamp, 'MM-DD-YYYY')}</span>
                            </h6>
                            <table className="min-w-full">
                                <thead className='hidden'>
                                    <tr>
                                        <th className="w-1/3 py-2 px-4"></th>
                                        <th className="w-1/3 bg-secondary/50 text-primary font-semibold py-2 px-4 rounded-tl-lg">Analysis</th>
                                        <th className="w-1/3 bg-secondary/10 text-primary font-semibold py-2 px-4 rounded-tr-lg">KPI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='h-1.5'>
                                        <td className="px-2 py-1 h-py-2 text-primary font-semibold">Length of Call</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center rounded-tl-lg">{formatTime(callLength)}</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center rounded-tr-lg">
                                            <span class={`${callLength < 180 ? 'bg-red-500' : (callLength > 300 ? 'bg-green-500' : 'bg-yellow-500')} inline-block w-4 h-4 rounded-full`}></span>
                                        </td>
                                    </tr>
                                    <tr className='h-1.5'>
                                        <td className="px-2 py-1 h-py-2 text-primary font-semibold">Caller Talk Time</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{callerTalkTime + '%'}</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                                            <span class={`${(callerTalkTime > 40 && callerTalkTime < 60) ? 'bg-green-500' : ((callerTalkTime > 80 || callerTalkTime < 20) ? 'bg-red-500' : 'bg-yellow-500')} inline-block w-4 h-4 rounded-full`}></span>
                                        </td>
                                    </tr>
                                    <tr className='h-1.5 hidden'>
                                        <td className="px-2 py-1 h-py-2 text-primary font-semibold">Followed Script</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center">{followedScript + '%'}</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center">
                                            <span class={`${followedScript < 50 ? 'bg-red-500' : (followedScript > 80 ? 'bg-green-500' : 'bg-yellow-500')} inline-block w-4 h-4 rounded-full`}></span>
                                        </td>
                                    </tr>
                                    <tr className='h-1.5'>
                                        <td className="px-2 py-1 h-py-2 text-primary font-semibold">Filler Words</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/50 text-blue-600 font-bold text-center rounded-bl-lg">{hmmNumber}</td>
                                        <td className="px-4 py-1 h-py-2 bg-secondary/10 text-blue-600 font-bold text-center rounded-br-lg">
                                            <span class={`${hmmNumber < 4 ? 'bg-green-500' : (hmmNumber > 7 ? 'bg-red-500' : 'bg-yellow-500')} inline-block w-4 h-4 rounded-full`}></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h6 className='text-primary text-center font-bold py-2 hidden'>
                                Analysis Score: <span className='text-blue-600 font-bold'>{analysisScore.toFixed(0)}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </BasicModal>
        </div>
    )
}

export default Playback
